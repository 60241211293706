import React from 'react';
import {ArrowLeft, Key, Mail, Fingerprint} from 'lucide-react';
import styles from "../LoginBottomSheet/LoginBottomSheet.module.css";

const LoginButtons = ({ onBack, logoSrc, hasPasskey, hasPassword, onPasskeyLogin, onSendOtp, onCurrentView, isLoading, onSubmit, styles }) => {
    return (
        <div className="w-full max-w-md mx-auto ">
            <div className={styles.header}>
                <button onClick={onBack} className={styles.backButton}>
                    <ArrowLeft size={24}/>
                </button>
                <div className={`${styles.logoWrapper} ${styles.logoBackButton}`}>
                    <img src={logoSrc} alt="Logo" className={styles.logo}/>
                </div>
            </div>


            <h2 className={styles.title}>
                Choose login method
            </h2>
            <h4 className={styles.subTitle}>
                Please choose an available login method for your account.
            </h4>


            <div className="space-y-2">
                <div className={`flex justify-between gap-1`}>
                    {hasPasskey && (
                        <button className={`${styles.btn} w-full flex items-center justify-between px-4 py-3 border-0 dark:border-gray-700 rounded-xxs hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors`} onClick={onPasskeyLogin}>
                            <div className="flex items-center space-x-3">
                                <Fingerprint className="w-5 h-5 text-white"/>
                                <span className="font-medium text-gray-900 dark:text-white">Sign in with passkey</span>
                            </div>
                        </button>
                    )}


                </div>

                <div style={{ borderWidth: '0.5px' }} className={`border-solid border-gray-100 flex w-full`}></div>

                <div className={`flex justify-between gap-1`}>
                    <button style={{ width: "100%" }}
                            className={`${styles.btn} w-full flex items-center justify-between px-4 py-3  border-0 dark:border-gray-700 rounded-xxs hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors`}
                            onClick={() => onSendOtp()}>
                        <div className="flex items-center space-x-3">
                            <Mail className="w-5 h-5 text-white"/>
                            <span className="font-medium text-gray-900 dark:text-white">Sign in with code</span>
                        </div>

                    </button>
                    {hasPassword && (
                        <button className={`${styles.btn} w-full flex items-center justify-between px-4 py-3 border-0 dark:border-gray-700 rounded-xxs hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors`}
                                onClick={() => onCurrentView('password')}>
                            <div className="flex items-center space-x-3">
                                <Key className="w-5 h-5 text-white"/>
                                <span className="font-medium text-gray-900 dark:text-white">Sign in with password</span>
                            </div>

                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default LoginButtons;
