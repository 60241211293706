import React from 'react';
import { ArrowLeft, Key, Mail, Fingerprint } from 'lucide-react';

const SignUpButtons = ({
                           onBack,
                           logoSrc,
                           onCreateWithPasskey,
                            onCreateWithOtp,
                           onCurrentView,
                           isLoading,
                           styles,
                           email
                       }) => {
    return (
        <div className="w-full max-w-md mx-auto">
            <div className={styles.header}>
                <button onClick={onBack} className={styles.backButton}>
                    <ArrowLeft size={24} />
                </button>
                <div className={`${styles.logoWrapper} ${styles.logoBackButton}`}>
                    <img src={logoSrc} alt="Logo" className={styles.logo} />
                </div>
            </div>

            <h2 className={styles.title}>
                Choose signup method
            </h2>

            <div className="space-y-2">
                {window.PublicKeyCredential && (
                    <>
                        <div className={`flex justify-between gap-1`}>
                            <button
                                className={`${styles.btn} w-full flex items-center justify-between px-4 py-3 border-0 dark:border-gray-700 rounded-xxs hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors`}
                                onClick={onCreateWithPasskey}
                                disabled={isLoading}
                            >
                                <div className="flex items-center space-x-3">
                                    <Fingerprint className="w-5 h-5 text-white" />
                                    <span className="font-medium text-gray-900 dark:text-white">
                                        Create account with passkey
                                    </span>
                                </div>
                            </button>
                        </div>

                        <div style={{ borderWidth: '0.5px' }} className={`border-solid border-gray-100 flex w-full`}></div>
                    </>
                )}

                <div className={`flex justify-between gap-1`}>
                    <button
                        style={{ width: "100%" }}
                        className={`${styles.btn} w-full flex items-center justify-between px-4 py-3 border-0 dark:border-gray-700 rounded-xxs hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors`}
                        onClick={onCreateWithOtp}
                        disabled={isLoading}
                    >
                        <div className="flex items-center space-x-3">
                            <Mail className="w-5 h-5 text-white" />
                            <span className="font-medium text-gray-900 dark:text-white">
                                Create account with email code
                              </span>
                        </div>
                    </button>

                    <button
                        className={`${styles.btn} w-full flex items-center justify-between px-4 py-3 border-0 dark:border-gray-700 rounded-xxs hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors`}
                        onClick={() => onCurrentView('setupPassword')}
                        disabled={isLoading}
                    >
                        <div className="flex items-center space-x-3">
                            <Key className="w-5 h-5 text-white" />
                            <span className="font-medium text-gray-900 dark:text-white">
                                Create account with password
                              </span>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SignUpButtons;
