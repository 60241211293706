import React, { useState, useRef } from 'react';

const OTPInput = ({ onComplete }) => {
    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const inputRefs = useRef([]);

    const handleChange = (index, value) => {
        if (!/^\d*$/.test(value)) return;

        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        if (value && index < 5) {
            inputRefs.current[index + 1].focus();
        }

        if (newOtp.every(digit => digit) && onComplete) {
            onComplete(newOtp.join(''));
        }
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData('text').trim();
        if (!/^\d{6}$/.test(pastedData)) return;

        const digits = pastedData.split('');
        setOtp(digits);
        inputRefs.current[5].focus();

        if (onComplete) {
            onComplete(pastedData);
        }
    };

    const handleKeyDown = (index, e) => {
        if (e.key === 'Backspace' && !otp[index] && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    return (
        <div className="flex gap-3 justify-center my-6">
            {otp.map((digit, index) => (
                <input
                    key={index}
                    ref={el => inputRefs.current[index] = el}
                    type="text"
                    inputMode="numeric"
                    maxLength={1}
                    value={digit}
                    onChange={e => handleChange(index, e.target.value)}
                    onKeyDown={e => handleKeyDown(index, e)}
                    onPaste={handlePaste}
                    className="w-12 h-12 text-center text-xl rounded-lg
                     bg-gray-800 dark:bg-gray-800
                     text-white dark:text-white
                     border border-gray-700 dark:border-gray-700
                     focus:border-purple-500 dark:focus:border-purple-500
                     focus:ring-2 focus:ring-purple-500 dark:focus:ring-purple-500
                     focus:outline-none
                     transition-colors"
                />
            ))}
        </div>
    );
};

export default OTPInput;
