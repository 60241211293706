import React, {useEffect, useRef, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { Mail, Lock, ArrowLeft } from 'lucide-react';
import styles from './LoginBottomSheet.module.css';
import { useTheme } from "../../ThemeContext/ThemeContext";
import logo from "../../../assets/logo2.svg";
import logoLight from "../../../assets/logo.svg";
import authApi from "../../../utils/auth.api";
import LottieIcon from "../../LottieIcon/LottieIcon";
import LoginButtons from "../LoginButtons/LoginButtons";
import OtpInput from "../OtpInput/OtpInput";
import SignUpButtons from "../SignUpButtons/SignUpButtons";
import constants from "../../../utils/constants";

const LoginBottomSheet = ({ isOpen, onClose, onLogin, onPasskeyLogin, onOtpLogin, unauthorizedData, handleOnboarding }) => {
    const bottomSheetRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startY, setStartY] = useState(0);
    const [currentTranslate, setCurrentTranslate] = useState(0);
    const [isSignUp, setIsSignUp] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [closing, setClosing] = useState(false);
    const [email, setEmail] = useState('');
    const [hasPasskey, setHasPasskey] = useState(false);
    const [hasPassword, setHasPassword] = useState(false);
    const [userId, setUserId] = useState('');
    const [password, setPassword] = useState('');
    const [currentView, setCurrentView] = useState('initial'); // initial, email, password, setup
    const [isLoading, setIsLoading] = useState(false);
    const [subTitle, setSubTitle] = useState('');
    const [error, setError] = useState('');
    const { isDarkMode } = useTheme();
    const logoSrc = isDarkMode ? logo : logoLight;
    const navigate = useNavigate();

    useEffect(() => {
        console.log('Is Open', isOpen);
    }, [isOpen]);

    const checkAccountAndInitiateAuth = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        try {
            const { exists, userId, hasPasskey, hasPassword } = await authApi.checkAccount(email);

            if (exists) {
                setIsSignUp(false)
                if (hasPasskey) {
                    setHasPasskey(true);
                    setUserId(userId);
                }
                if(hasPassword){
                    setHasPassword(true);
                }

                setCurrentView('chooseLogin');
            } else {
                setIsSignUp(true);
                setCurrentView('setup');
            }
        } catch (error) {
            console.error('Account check failed:', error);
            setError('Unable to check account. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handlePasswordLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        try {
            const response = await onLogin(email, password);
            console.log(response);
            if(response.success) {
                setClosing(true);

                setTimeout(() => {
                    resetForm();
                    onClose();
                }, 300);

            }
        } catch (error) {
            console.error('Login failed:', error);
            setError('Invalid email or password.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleOtpLogin = async (otp) => {
        try {
            setIsLoading(true);
            setError('');

            const response = await onOtpLogin(email, otp);

            resetForm();
            onClose();
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSendOtp = async () => {
        const response = await authApi.sendOtp(email);
        if(response.otp){
            setCurrentView('otp');
        }
    }

    const resetForm = () => {
        setClosing(false);
        setEmail('');
        setPassword('');
        setCurrentView('initial');
    }

    const handleGoogleLogin = async () => {
        setIsLoading(true);
        setError('');

        try {
            await authApi.googleAuth();
            // Note: This won't actually execute since googleAuth redirects the page
        } catch (error) {
            console.error('Google login failed:', error);
            setError(error.message || 'Failed to connect with Google. Please try again.');
            setIsLoading(false);
        }
    };

    const handleSignInPasskey = async () => {
        const result = await authApi.authenticateWithPasskey(email, userId);
        if (result?.token) {
            localStorage.setItem('user', JSON.stringify(result));
            onPasskeyLogin(result);
            resetForm();
            onClose();
        }
    }

    const handleCreateAccount = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        try {
            // First check if WebAuthn is available
            if (!window.PublicKeyCredential) {
                throw new Error('WebAuthn is not supported in this browser');
            }

            // Explicitly check platform authenticator availability
            const available = await PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
            if (!available) {
                throw new Error('Your device does not support biometric/PIN authentication');
            }

            setSubTitle('Please follow your device\'s prompts to set up biometric login');

            // Add a small delay to ensure the UI updates before proceeding
            await new Promise(resolve => setTimeout(resolve, 100));

            const result = await authApi.createAccountWithPasskey(email);




            console.log(result);
            if (!result?.token) {
                throw new Error('Account creation failed: ' + (result?.message ?? 'Invalid server response.'));
            }

            // Add a small delay before closing to ensure the passkey is saved
            await new Promise(resolve => setTimeout(resolve, 500));

            handleOnboarding(result);
            onClose();
        } catch (error) {
            console.error('Account creation failed:', error);

            if (error.name === 'NotAllowedError') {
                setError('Permission denied. Please try again and follow the prompts.');
            } else if (error.name === 'SecurityError') {
                setError('Security error. Please ensure you\'re using HTTPS.');
            } else {
                setError('Unable to create account. Please try again.');
            }
        } finally {
            setIsLoading(false);
            setSubTitle('We\'ll create an account for ' + email);
        }
    };

    const handleCreateWithPassword = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        try {
            const response = await authApi.register(email, newPassword);
            if (response?.data?.token) {
                console.log(response);
                const userData = await authApi.login(email, newPassword);
                handleOnboarding(userData);
                resetForm();
                onClose();
            }
        } catch (error) {
            setError(error.message || 'Unable to create account. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleSetupWithOtp = async () => {
        const response = await authApi.createTempUser(email);
        if(response.success){
            const otpResponse = await authApi.sendOtp(email);
            if(otpResponse?.otp){
                setCurrentView('setupOtp');
            } else {
                setError('Error sending OTP.')
            }
        } else {
            setError(response.message);
        }
    }

    const handleCreateWithOtp = async (otp) => {
        try {
            setIsLoading(true);
            setError('');

            const response = await authApi.createAccountWithOtp(email, otp);
            if (response?.data?.token) {
                handleOnboarding(response.data);
                resetForm();
                onClose();
            }
        } catch (error) {
            setError(error.message || 'Unable to create account. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleBack = () => {
        setError('');
        if (currentView === 'password') {
            setCurrentView('email');
            setPassword('');
        } else if (currentView === 'email') {
            setCurrentView('initial');
            setEmail('');
        } else if (currentView === 'setup') {
            setCurrentView('email');
        } else if (currentView === 'chooseLogin') {
            setCurrentView('email');
        } else if (currentView === 'otp') {
            setCurrentView('email');
        } else if (currentView === 'setupOtp') {
            setCurrentView('setup');
        } else if (currentView === 'setupPassword') {
            setCurrentView('setup');
        }
    };

    const handleClose = (e) => {
        e?.preventDefault();
        setCurrentView('initial');
        setEmail('');
        setPassword('');
        setError('');
        setClosing(true);

        if (bottomSheetRef.current) {
            bottomSheetRef.current.style.transition = 'transform 0.3s ease-out';
            bottomSheetRef.current.style.transform = 'translateY(100%)';
        }

        setTimeout(() => {
            setClosing(false);
            onClose();
        }, 300);
    };

    const handleTouchStart = (e) => {
        e.preventDefault(); // Prevent browser refresh
        setIsDragging(true);
        setStartY(e.touches[0].clientY);
        setCurrentTranslate(0);

        if (bottomSheetRef.current) {
            bottomSheetRef.current.style.transition = 'none';
        }
    };

    const handleTouchMove = (e) => {
        if (!isDragging) return;

        const currentY = e.touches[0].clientY;
        const deltaY = currentY - startY;

        // Only allow pulling down, not up
        if (deltaY < 0) return;

        // Add resistance to the pull
        const translate = Math.min(deltaY * 0.5, 200);
        setCurrentTranslate(translate);

        if (bottomSheetRef.current) {
            bottomSheetRef.current.style.transform = `translateY(${translate}px)`;
        }
    };

    const handleTouchEnd = () => {
        setIsDragging(false);

        if (bottomSheetRef.current) {
            bottomSheetRef.current.style.transition = 'all 0.3s ease';

            // If pulled down more than 100px, close the sheet
            if (currentTranslate > 100) {
                handleClose();
            } else {
                // Reset position
                bottomSheetRef.current.style.transform = 'translateY(0)';
            }
        }
    };

    if (!isOpen) return null;

    return (
        <div
            className={`${styles.overlay} ${closing ? styles.overlayClosing:''}`}
            onClick={(e) => {
                if (e.target.classList.contains(styles.overlay)) {
                    handleClose(e);
                }
            }}
        >
            <div
                className={`${styles.bottomSheet} ${isOpen && !closing ? styles.bottomSheetOpen : ''} ${closing ? styles.bottomSheetClosing:''}  ${isDarkMode ? styles.dark : ''}`}
                style={{transform: 'translateY()'}}
                ref={bottomSheetRef}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={styles.handleTouchArea}
                     onTouchStart={handleTouchStart}
                     onTouchMove={handleTouchMove}
                     onTouchEnd={handleTouchEnd}>
                    <div className={styles.handle}
                         >
                        <div className={styles.handleBar}></div>
                    </div>
                </div>


                {error && (
                    <div className={styles.error}>
                        {error}
                    </div>
                )}

                {/* Initial View */}
                {currentView === 'initial' && (
                    <>
                        <div className={styles.logoWrapper}>
                            <img src={logoSrc} alt="Logo" className={styles.logo}/>
                        </div>
                        {/*<div className={styles.lottieIcon}>
                            <LottieIcon width={210} height={210} variant={'pluug'} className={styles.lottieSvg} />
                        </div>*/}

                        {unauthorizedData?.onProduct ? (
                            <h2 className={styles.title}>
                                Sign in or create an account<br/> to claim this deal.
                            </h2>
                        ) : (
                             <h2 className={styles.title}>
                                 Sign in or create a free account.
                             </h2>
                         )}

                        <h4 className={styles.subTitle}>
                            Receive deal alerts, save deals, set deal preferences and more with your pluug account.
                        </h4>

                        <button
                            type="button"
                            className={styles.submitButton}
                            onClick={() => setCurrentView('email')}
                        >
                            <Mail size={24}/> Sign In or Sign Up
                        </button>

                        <div className={styles.orDivider}>
                            <span>or</span>
                        </div>

                        <button
                            type="button"
                            className={`${styles.submitButton} ${styles.googleButton}`}
                            onClick={handleGoogleLogin}
                            disabled={isLoading}
                        >
                            <img
                                src="https://developers.google.com/identity/images/g-logo.png"
                                alt="Google logo"
                                className={styles.googleIcon}
                            />
                            Continue with Google
                        </button>

                        <div className={`my-3 text-sm`}>
                            <p className="text-sm text-center text-gray-500 dark:text-gray-400">
                                By continuing, you confirm that you are of <br /><span className={`font-bold text-white`}>legal age in your jurisdiction</span> and you agree to our <br />

                                <a href={constants.TOS_URL} target={`_blank`} className="dark:text-brand-green text-brand-purple hover:underline underline ml-1 mr-1">Terms of Use</a>
                                and
                                <a href={constants.PRIVACY_URL} target={`_blank`} className="dark:text-brand-green text-brand-purple  hover:underline underline ml-1">Privacy Policy</a>.
                            </p>
                        </div>
                    </>
                )}

                {/* Email Input View */}
                {currentView === 'email' && (
                    <>
                        <div className={styles.header}>
                            <button onClick={handleBack} className={styles.backButton}>
                                <ArrowLeft size={24}/>
                            </button>
                            <div className={`${styles.logoWrapper} ${currentView !== 'initial' ? styles.logoBackButton:''}`}>
                                <img src={logoSrc} alt="Logo" className={styles.logo} />
                            </div>
                        </div>

                        <h2 className={styles.title}>
                            What's your email?
                        </h2>

                        <form onSubmit={checkAccountAndInitiateAuth} className={styles.form}>
                            <div className={styles.inputGroup}>
                                <Mail className={styles.icon} size={20} />
                                <input
                                    className={styles.input}
                                    type="email"
                                    value={email}
                                    placeholder="Email Address"
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            {/*
                            <div className={styles.lottieIcon}>
                                <LottieIcon width={100} height={100} variant={'email07'} className={styles.lottieSvg}/>
                                <LottieIcon width={180} height={180} variant={'email02'} className={styles.lottieSvg}/>
                            </div> */}

                            <button
                                type="submit"
                                className={styles.submitButton}
                                disabled={isLoading}
                            >
                                {isLoading ? 'Please wait...' : 'Continue'}
                            </button>
                        </form>
                    </>
                )}

                {currentView === 'chooseLogin' && (
                    <LoginButtons hasPassword={hasPassword}
                                  hasPasskey={hasPasskey}
                                  onCurrentView={setCurrentView}
                                  onPasskeyLogin={handleSignInPasskey}
                                  onSendOtp={handleSendOtp}
                                  logoSrc={logoSrc}
                                  isLoading={isLoading}
                                  onBack={handleBack}
                                  styles={styles}
                                  onSubmit={handlePasswordLogin} />
                )}

                {currentView === 'otp' && (
                    <>
                        <div className={styles.header}>
                            <button onClick={handleBack} className={styles.backButton}>
                                <ArrowLeft size={24}/>
                            </button>
                            <div className={`${styles.logoWrapper} ${currentView !== 'initial' ? styles.logoBackButton : ''}`}>
                                <img src={logoSrc} alt="Logo" className={styles.logo}/>
                            </div>
                        </div>

                        <h2 className={styles.title}>
                            Check your email
                        </h2>
                        <h4 className={styles.subTitle}>
                            Please enter the OTP sent to your email {email}
                        </h4>

                        <OtpInput onComplete={handleOtpLogin}/>

                        <div className={styles.lottieIcon}>
                            <LottieIcon width={300} height={300} variant={'otp'} className={styles.lottieSvg}/>
                        </div>

                    </>

                )}

                {/* Password Input View */}
                {currentView === 'password' && (
                    <>
                        <div className={styles.header}>
                            <button onClick={handleBack} className={styles.backButton}>
                            <ArrowLeft size={24} />
                            </button>
                            <div className={`${styles.logoWrapper} ${currentView !== 'initial' ? styles.logoBackButton:''}`}>
                                <img src={logoSrc} alt="Logo" className={styles.logo} />
                            </div>
                        </div>

                        <h2 className={styles.title}>
                            Enter your password.
                        </h2>
                        <h4 className={styles.subTitle}>
                            Please enter your password for {email}
                        </h4>

                        <form onSubmit={handlePasswordLogin} className={styles.form}>
                            <div className={styles.inputGroup}>
                                <Lock className={styles.icon} size={20} />
                                <input
                                    className={styles.input}
                                    type="password"
                                    value={password}
                                    placeholder="Password"
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </div>
                            {/* <div className={styles.lottieIcon}>
                                <LottieIcon width={310} height={250} variant={'manuscript'} className={styles.lottieSvg}/>
                            </div>*/}

                            <button
                                type="submit"
                                className={styles.submitButton}
                                disabled={isLoading}
                            >
                                {isLoading ? 'Please wait...' : 'Sign in'}
                            </button>
                        </form>
                    </>
                )}

                {/* Account Setup View */}
                {currentView === 'setup' && (
                    <SignUpButtons
                        onBack={handleBack}
                        logoSrc={logoSrc}
                        onCreateWithPasskey={handleCreateAccount}
                        onCreateWithOtp={handleSetupWithOtp}
                        onCurrentView={setCurrentView}
                        isLoading={isLoading}
                        styles={styles}
                        email={email}
                    />
                )}

                {currentView === 'setupPassword' && (
                    <>
                        <div className={styles.header}>
                            <button onClick={handleBack} className={styles.backButton}>
                                <ArrowLeft size={24} />
                            </button>
                            <div className={`${styles.logoWrapper} ${styles.logoBackButton}`}>
                                <img src={logoSrc} alt="Logo" className={styles.logo} />
                            </div>
                        </div>

                        <h2 className={styles.title}>
                            Create your password
                        </h2>
                        <h4 className={styles.subTitle}>
                            Please create a secure password for {email}
                        </h4>

                        <form onSubmit={handleCreateWithPassword} className={styles.form}>
                            <div className={styles.inputGroup}>
                                <Lock className={styles.icon} size={20}/>
                                <input
                                    className={styles.input}
                                    type="password"
                                    value={newPassword}
                                    placeholder="Password"
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    required
                                    minLength={8}
                                />
                            </div>
                            {/*<div className={styles.lottieIcon}>
                                <LottieIcon width={310} height={250} variant={'manuscript'} className={styles.lottieSvg}/>
                            </div>*/}

                            <button
                                type="submit"
                                className={styles.submitButton}
                                disabled={isLoading}
                            >
                                {isLoading ? 'Please wait...' : 'Create Account'}
                            </button>
                        </form>
                    </>
                )}

                {currentView === 'setupOtp' && (
                    <>
                        <div className={styles.header}>
                        <button onClick={handleBack} className={styles.backButton}>
                                <ArrowLeft size={24}/>
                            </button>
                            <div className={`${styles.logoWrapper} ${styles.logoBackButton}`}>
                                <img src={logoSrc} alt="Logo" className={styles.logo}/>
                            </div>
                        </div>

                        <h2 className={styles.title}>
                            Check your email
                        </h2>
                        <h4 className={styles.subTitle}>
                            Please enter the verification code sent to {email}
                        </h4>

                        <OtpInput onComplete={handleCreateWithOtp}/>

                        <div className={styles.lottieIcon}>
                            <LottieIcon width={300} height={300} variant={'otp'} className={styles.lottieSvg}/>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default LoginBottomSheet;
