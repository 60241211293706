// src/components/Auth/GoogleAuthCallback.js
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {createCryptoInterceptor} from "../../../utils/crypto-interceptor";

const GoogleAuthCallback = ({ onLogin, handleShowHeader, handleShowSettingsHeader, handleOnboarding, setUser }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // Hide headers for this page
        handleShowHeader(false);
        handleShowSettingsHeader(false);

        const processAuth = async () => {
            try {
                setLoading(true);
                // Get URL query parameters
                const queryParams = new URLSearchParams(location.search);
                const token = queryParams.get('token');
                const userData = queryParams.get('userData');
                const errorMsg = queryParams.get('error');

                if (errorMsg) {
                    throw new Error(decodeURIComponent(errorMsg));
                }

                console.log("GOOGLE AUTH CALLBACK");


                if (userData) {

                    // Parse and decrypt the user data
                    const parsedData = JSON.parse(decodeURIComponent(userData));

                    const decryptedData = await createCryptoInterceptor(() => parsedData)();
                    console.log(decryptedData);
                    // Store user data in localStorage
                    if (decryptedData?.token) {
                        const userData = JSON.stringify(decryptedData);
                        setUser(decryptedData)
                        localStorage.setItem('user', userData);

                        // Check if this is a new user (for onboarding flow)
                        const isNewUser = decryptedData.is_new_user;

                        if (isNewUser && handleOnboarding) {
                            // Handle onboarding for new users
                            handleOnboarding(decryptedData);
                        } else {
                            // Navigate to home for existing users
                            navigate('/');
                        }
                    } else {
                        throw new Error('Invalid authentication data: missing token');
                    }
                } else {
                    throw new Error('Invalid authentication data: missing user data');
                }
            } catch (error) {
                console.error('Google auth error:', error);
                setError(error.message || 'Authentication failed');
            } finally {
                setLoading(false);
            }
        };

        processAuth();

        // Cleanup function to restore headers
        return () => {
            handleShowHeader(true);
            handleShowSettingsHeader(false);
        };
    }, []);

    if (loading) {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                padding: '20px',
                textAlign: 'center'
            }}>
                <h2>Completing Google Sign In...</h2>
                <div style={{
                    border: '4px solid rgba(0, 0, 0, 0.1)',
                    borderRadius: '50%',
                    borderTop: '4px solid #3498db',
                    width: '40px',
                    height: '40px',
                    animation: 'spin 1s linear infinite',
                    margin: '20px auto'
                }}></div>
                <style>{`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}</style>
            </div>
        );
    }

    if (error) {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                padding: '20px',
                textAlign: 'center'
            }}>
                <h2>Authentication Failed</h2>
                <p>{error}</p>
                <button
                    onClick={() => navigate('/')}
                    style={{
                        marginTop: '20px',
                        padding: '10px 20px',
                        background: '#3498db',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer'
                    }}
                >
                    Return to Home
                </button>
            </div>
        );
    }

    return null;
};

export default GoogleAuthCallback;
