import React, {createContext, useState, useContext} from 'react';
import { debounce } from 'lodash';

const BrandContext = createContext();

export const BrandProvider = ({ children }) => {
    const [brands, setBrands] = useState([]);
    const [brandsSelected, setBrandsSelected] = useState([]);
    const [brandsUnselected, setBrandsUnselected] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const loadBrands = async (api, geoposition) => {
        if (!geoposition) {
            console.error('No geoposition provided for brand loading');
            return;
        }
        const userGeo = JSON.parse(localStorage.getItem('currentGeo'));

        try {
            setIsLoading(true);
            setError(null);
            const query = {
                state: userGeo?.state_abbr,
                geo: geoposition,
                sortBy: 'near_by',
                page: 1,
                distance: 10,
            };

            const results = await api.selectFavoriteBrands(query);
            if (results?.data?.unselected?.length > 0) {
                // Transform the data to include selected status
                setAllBrandTypes(results);
            }
        }
        catch (err) {
            console.error('Error loading brands:', err);
            setError(err.message);
        }
        finally {
            setIsLoading(false);
        }
    };

    const setAllBrandTypes = (results) => {
        const transformedBrands = results.data.brands.map(brand => ({
            ...brand,
            selected: Boolean(brand.selected) // Ensure it's a boolean
        }));

        const transformedSelectedBrands = results.data.selected.map(brand => ({
            ...brand,
            selected: Boolean(brand.selected) // Ensure it's a boolean
        }));

        const transformedUnselectedBrands = results.data.unselected.map(brand => ({
            ...brand,
            selected: Boolean(brand.selected) // Ensure it's a boolean
        }));
        setBrands(transformedBrands);
        setBrandsSelected(transformedSelectedBrands);
        setBrandsUnselected(transformedUnselectedBrands);
    }

    const updateBrandSelection = (brandId, isSelected) => {
        setBrands(prevBrands =>
                                prevBrands.map(brand =>
                                                   brand.id === brandId
                                                   ? { ...brand, selected: isSelected }
                                                   : brand
                                )
        );

        setBrandsUnselected(prevBrands =>
                      prevBrands.map(brand =>
                                         brand.id === brandId
                                         ? { ...brand, selected: isSelected }
                                         : brand
                      )
        );

        setBrandsSelected(prevBrands =>
                      prevBrands.map(brand =>
                                         brand.id === brandId
                                         ? { ...brand, selected: isSelected }
                                         : brand
                      )
        );
    };

    const getSelectedBrandIds = () => {
        return brands
            .filter(brand => brand.selected)
            .map(brand => brand.id);
    };

    const searchBrands = debounce(async (api, term) => {
        if (term.length >= 2) {
            try {
                setIsLoading(true);
                const response = await api.searchBrandsOnboarding(term);
                setAllBrandTypes(response);
                setIsLoading(false);
            } catch (error) {
                console.error('Error searching brands:', error);
                setIsLoading(false);
            }
        }
    }, 300);

    const value = {
        brandsSelected,
        brandsUnselected,
        brands,
        setBrands,
        isLoading,
        error,
        searchBrands,
        loadBrands,
        updateBrandSelection,
        getSelectedBrandIds
    };

    return (
        <BrandContext.Provider value={value}>
            {children}
        </BrandContext.Provider>
    );
};

export const useBrands = () => {
    const context = useContext(BrandContext);
    if (!context) {
        throw new Error('useBrands must be used within a BrandProvider');
    }
    return context;
};
