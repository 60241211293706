import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import styles from './Register.module.css';
import {IoPersonCircleOutline} from "react-icons/io5";
import {MdOutlineEmail} from "react-icons/md";
import {CgLock} from "react-icons/cg";
import {FaThreads} from "react-icons/fa6";
import {Eye, EyeOff} from 'lucide-react';
import logo from '../../assets/logo2.svg';
import {useTheme} from "../ThemeContext/ThemeContext";
import {useNavVisibility} from '../NavVisibilityContext/NavVisibilityContext';

const Register = ({ onRegister, handleShowHeader, geoposition, error }) => {
    const [firstName, setFirstName] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [apiError, setApiError] = useState('');
    const { isDarkMode } = useTheme();
    const logoSrc = isDarkMode ? logo : logo;
    const { setIsNavVisible } = useNavVisibility();

    const debug = false;

    if (debug) {
        useEffect(() => {
            let userInfo = {
                firstName: 'Brenden',
                email: 'bwake90@gmail.com',
                username: 'brenden',
                password: 'brenden',
                confirmPassword: 'brenden',
            }
            setFirstName(userInfo.firstName);
            setEmail(userInfo.email);
            setUsername(userInfo.username);
            setPassword(userInfo.password);
            setConfirmPassword(userInfo.confirmPassword);
        }, []);
    }

    useEffect(() => {
        console.log(error);
        setApiError(error);
    }, [error])

    useEffect(() => {
        handleShowHeader(false);
        setIsNavVisible(false);
    }, [handleShowHeader, setIsNavVisible]);

    useEffect(() => {
        validateForm();
    }, [firstName, username, email, password, confirmPassword]);

    const validateForm = () => {

        if (!firstName || !username || !email || !password || !confirmPassword) {
            setErrorMessage('Please fill in all fields.');
            return false;
        }
        if (password !== confirmPassword) {
            setErrorMessage('Passwords do not match.');
            return false;
        }
        setErrorMessage('');
        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            onRegister(email, password, firstName, username);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const isFormValid = firstName && username && email && password && confirmPassword && password === confirmPassword;

    return (
        <div className={styles.container}>
            <div className={styles.logoContainer}>
                <img className={styles.logo} src={logoSrc} alt="logo"/>
            </div>
            <div className={styles.formContainer}>
                <div className={styles.formCard}>
                    <h2 className={styles.title}>Sign up to shop & save weed deals near you.</h2>
                    <form onSubmit={handleSubmit} className={styles.form}>
                        <div className={styles.inputWrap}>
                            <div className={styles.inputGroup}>
                                <div className={styles.inputWrapper}>
                                    <IoPersonCircleOutline className={styles.icon}/>
                                    <input
                                        className={styles.input}
                                        type="text"
                                        id="firstname"
                                        value={firstName}
                                        placeholder="First name"
                                        onChange={(e) => setFirstName(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className={styles.inputGroup}>
                                <div className={styles.inputWrapper}>
                                    <MdOutlineEmail className={styles.icon}/>
                                    <input
                                        className={styles.input}
                                        type="email"
                                        id="email"
                                        value={email}
                                        placeholder="Email address"
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className={styles.inputGroup}>
                                <div className={styles.inputWrapper}>
                                    <FaThreads className={styles.icon}/>
                                    <input
                                        className={styles.input}
                                        type="text"
                                        id="username"
                                        value={username}
                                        placeholder="Username"
                                        onChange={(e) => setUsername(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className={styles.inputGroup}>
                                <div className={styles.inputWrapper}>
                                    <CgLock className={styles.icon}/>
                                    <input
                                        className={styles.input}
                                        type={showPassword ? "text" : "password"}
                                        id="password"
                                        value={password}
                                        placeholder="Password"
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                    <button
                                        type="button"
                                        onClick={togglePasswordVisibility}
                                        className={styles.togglePassword}
                                    >
                                        {showPassword ? (
                                            <EyeOff className={styles.icon}/>
                                        ) : (
                                             <Eye className={styles.icon}/>
                                         )}
                                    </button>
                                </div>
                            </div>
                            <div className={styles.inputGroup}>
                                <div className={styles.inputWrapper}>
                                    <CgLock className={styles.icon}/>
                                    <input
                                        className={styles.input}
                                        type={showConfirmPassword ? "text" : "password"}
                                        id="confirmPassword"
                                        value={confirmPassword}
                                        placeholder="Confirm Password"
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        required
                                    />
                                    <button
                                        type="button"
                                        onClick={toggleConfirmPasswordVisibility}
                                        className={styles.togglePassword}
                                    >
                                        {showConfirmPassword ? (
                                            <EyeOff className={styles.icon}/>
                                        ) : (
                                             <Eye className={styles.icon}/>
                                         )}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className={styles.buttonGroup}>
                            <div className={styles.loginLink}>
                                <span>Already have an account? </span>
                                <Link to="/login">Login here</Link>
                            </div>
                            <button
                                className={`${styles.submitButton} ${!isFormValid ? styles.disabledButton : ''}`}
                                type="submit"
                                disabled={!isFormValid}
                            >
                                Sign Up
                            </button>
                            {(errorMessage || apiError) && <div className={styles.errorMessage}>{errorMessage || apiError}</div>}

                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Register;
